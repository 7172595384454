import {
  EntityAdapter,
  EntityState,
  PayloadAction,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import {
  ComplexAnswerPublicJSON,
  InterviewAccessErrorsEnum,
  InterviewPublic,
  InterviewQuestion,
  InterviewStatusEnum,
  PosthogEventTypesEnum,
  ProjectPublic,
  TranscriptFragmentSaveStatusEnum as SaveStatusEnum,
  TranscriptFragmentPublicJSON,
  isComplexInterviewQuestion,
} from "app-types";
import axios from "axios";
import posthog from "posthog-js";
import { Selector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { createAxiosInstance } from "../../api/axiosConfig";
import { audioBlobManager } from "../../app/audioBlobManager";
import { RootState } from "../../app/store";
import {
  ComplexAnswerPublic,
  saveComplexAnswer,
  selectAllComplexAnswers,
} from "../complexAnswers/complexAnswersSlice";
import {
  initiateInterviewForProjectLink,
  selectProjectLinkState,
  validateProjectLink,
} from "../projectLink/projectLinkSlice";

/*
 * Types
 */

export type TranscriptFragmentPublic = {
  id: string;
  start_time: number;
  end_time: number | null;
  question: string;
  is_dynamic_question: boolean;
  save_status: SaveStatusEnum;
  error: string | null;
};

type TranscriptFragmentsState = EntityState<TranscriptFragmentPublic> & {
  token: string | null;
  interview: InterviewPublic | null;
  interview_loading_status: "idle" | "loading" | "succeeded" | "failed";
  interview_error: string | null;
};

/*
 * Async actions
 */

// Initial load of interview
export interface InterviewDataResponse {
  interview: InterviewPublic;
  transcript_fragments: TranscriptFragmentPublicJSON[];
  project: ProjectPublic;
  complex_answers: ComplexAnswerPublicJSON[];
}

export const getInterviewData = createAsyncThunk<
  InterviewDataResponse,
  void,
  { rejectValue: string }
>("interview/get", async (_, thunkAPI) => {
  const token = (thunkAPI.getState() as RootState).transcriptFragments.token;

  try {
    const axiosInstance = createAxiosInstance(token);
    const response = await axiosInstance.get("/interview");

    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error) && error.response) {
      const errorCode = error.response.data.errorCode;

      if (Object.values(InterviewAccessErrorsEnum).includes(errorCode)) {
        return thunkAPI.rejectWithValue(errorCode);
      }
    }

    return thunkAPI.rejectWithValue(error.message);
  }
});

type PostTranscriptResult = {
  dynamic_question?: string;
};

const MAX_WAIT_TIME = 12000; // 12 seconds

// Saves a transcript to the server and retrieves the next question
interface PostTranscriptPayload {
  id: string;
  nextTranscriptFragmentStartTime?: number;
}
export const postTranscriptFragment = createAsyncThunk<
  PostTranscriptResult,
  PostTranscriptPayload,
  { rejectValue: string }
>(
  "transcriptFragments/postTranscriptFragment",
  async (payload: PostTranscriptPayload, thunkAPI) => {
    const rootState = thunkAPI.getState() as RootState;

    const transcriptFragment =
      rootState.transcriptFragments.entities[payload.id];
    if (!transcriptFragment)
      throw new Error("No transcript fragment found in state");
    const interviewQuestions =
      rootState.transcriptFragments.interview?.questions;
    const token = rootState.transcriptFragments.token;

    const { project } = selectProjectLinkState(rootState);

    if (!interviewQuestions)
      throw new Error("No interview questions found in state");

    const { id, question, start_time, end_time, is_dynamic_question } =
      transcriptFragment;
    if (!question || !end_time)
      throw new Error("Missing required transcript properties");

    const audioBlobInfo = audioBlobManager.getBlob(id);
    if (!audioBlobInfo)
      throw new Error("No audio blob found for transcript fragment");

    try {
      const formData = new FormData();
      formData.append("id", id);
      formData.append("audio", audioBlobInfo.blob);
      formData.append("mime_type", audioBlobInfo.mimeType);
      formData.append("question", question);
      formData.append("is_dynamic_question", is_dynamic_question.toString());
      formData.append("start_time", new Date(start_time).toISOString());
      formData.append("end_time", new Date(end_time).toISOString());
      formData.append("questions", JSON.stringify(interviewQuestions));
      // Check for type safety, but we should always have a project loaded by the time we're posting transcripts
      formData.append("company_name", project?.company_name || "");

      // If we're retrying a save, indicate that for the server
      const isSaveRetry = !payload.nextTranscriptFragmentStartTime;
      if (isSaveRetry) {
        formData.append("is_save_retry", "true");
      }

      // If we have a token, use bearer token auth. Otherwise, we must have an
      // auth cookie that will automatically be included in the request.
      const axiosInstance = createAxiosInstance(token, {
        enableRetries: true,
      });
      const axiosPromise = axiosInstance.post(
        "/interview/transcript",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // If clarifying question is taking too long to generate, move on after MAX_WAIT_TIME
      // We don't use axios timeout because we still want to allow successful save to happen in background.
      // Dont enforce maxWaitTime when we're retrying saves
      const maxWaitPromise = new Promise<null>((resolve) => {
        setTimeout(() => resolve(null), MAX_WAIT_TIME);
      });
      const requestResult = await Promise.race(
        isSaveRetry ? [axiosPromise] : [axiosPromise, maxWaitPromise]
      );

      // If the timeoutPromise wins the race, requestResult will be null
      if (requestResult === null) {
        console.warn("Max wait time reached");
        // Reject so that we retry request later in case it fails
        return thunkAPI.rejectWithValue("Max wait time reached");
      }

      // Otherwise, the axios request was successful before the timeout
      const { data } = requestResult;
      const result: PostTranscriptResult = {
        dynamic_question: data.dynamic_question,
      };
      return thunkAPI.fulfillWithValue(result);
    } catch (error: any) {
      console.log("Save error:", error);
      if (error.response) {
        // The request was made and the server responded with a status code that falls out of the range of 2xx
        console.error(
          "Save error: Server responded with a non-2xx status code"
        );
        console.error("Status:", error.response.status);
        console.error("Headers:", error.response.headers);
        console.error("Data:", error.response.data);
      } else if (error.request) {
        // The request was made but no response was received (server didn't respond or wasn't reachable)
        console.error("Save error: No response received");
        console.error("Request:", error.request);
      } else {
        // Something happened in setting up the request that triggered an error
        console.error("Save error: Error in setting up the request");
        console.error("Message:", error.message);
      }

      if (error.config) console.error("Config:", error.config);

      // Log additional information if available
      console.error("Form Data:", {
        id,
        question,
        isDynamicQuestion: is_dynamic_question,
        startTime: start_time,
        endTime: end_time,
        interviewQuestions,
        companyName: project?.company_name || "",
      });
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

/*
 * Reducer
 */

export const transcriptFragmentsAdapter =
  createEntityAdapter<TranscriptFragmentPublic>({
    sortComparer: (a, b) => {
      // Sorts transcriptFragments by start time, so we can easily retrieve the most recent one
      return a.start_time - b.start_time;
    },
  });

const initialState: TranscriptFragmentsState = {
  ...transcriptFragmentsAdapter.getInitialState(),
  token: getInterviewTokenFromUrl(),
  interview: null,
  interview_loading_status: "idle",
  interview_error: null,
};

interface AddNextTranscriptFragmentOptions {
  nextTranscriptFragmentStartTime: number;
  dynamicQuestion?: string;
}

const transcriptFragmentsSlice = createSlice({
  name: "transcriptFragments",
  initialState,
  reducers: {
    transcriptFragmentUpdated: transcriptFragmentsAdapter.updateOne,
    transcriptFragmentAdded: transcriptFragmentsAdapter.addOne,
    addNextTranscriptFragment: (
      state,
      action: PayloadAction<AddNextTranscriptFragmentOptions>
    ) => {
      addNextTranscriptFragmentToState(
        state,
        action.payload.nextTranscriptFragmentStartTime,
        action.payload.dynamicQuestion
      );
    },
  },
  extraReducers: (builder) => {
    builder
      // Handles fetching of interview data + existing transcriptFragments
      .addCase(getInterviewData.pending, (state) => {
        state.interview_loading_status = "loading";
      })
      .addCase(getInterviewData.fulfilled, (state, action) => {
        state.interview_loading_status = "succeeded";

        const { interview, transcript_fragments } = action.payload;
        state.interview = interview;

        mapAndSetTranscriptFragments(
          state,
          transcript_fragments,
          transcriptFragmentsAdapter
        );
      })
      .addCase(getInterviewData.rejected, (state, action) => {
        state.interview_loading_status = "failed";
        state.interview_error =
          action.payload || InterviewAccessErrorsEnum.UNKNOWN;
      })

      .addCase(validateProjectLink.fulfilled, (state, action) => {
        const { interview, transcript_fragments } = action.payload;

        // When a project link is validated, we are guaranteed to get a project
        // but there may not be an interview yet.
        if (!interview || !transcript_fragments) {
          return;
        }

        state.interview = interview;

        mapAndSetTranscriptFragments(
          state,
          transcript_fragments,
          transcriptFragmentsAdapter
        );
      })
      .addCase(saveComplexAnswer.pending, (state, action) => {
        // Fill in logic from postTranscriptFragment.pending
        markPreInterviewSurveyQuestionAsAnswered(state, action.meta.arg);
      })
      // Handles saving transcripts and receiving next question
      .addCase(postTranscriptFragment.pending, (state, action) => {
        // Update state to show that we're attempting to save this fragment
        transcriptFragmentsAdapter.updateOne(state, {
          id: action.meta.arg.id,
          changes: {
            save_status: SaveStatusEnum.SAVING,
          },
        });

        // We only want to mark the latest question as answered on the original save attempt
        const isSaveRetry = !action.meta.arg.nextTranscriptFragmentStartTime;
        if (!isSaveRetry) markLatestQuestionAsAnswered(state);
      })
      .addCase(postTranscriptFragment.fulfilled, (state, action) => {
        const { id, nextTranscriptFragmentStartTime } = action.meta.arg;

        transcriptFragmentsAdapter.updateOne(state, {
          id: id,
          changes: {
            save_status: SaveStatusEnum.SAVED,
            error: null,
          },
        });

        if (nextTranscriptFragmentStartTime)
          addNextTranscriptFragmentToState(
            state,
            nextTranscriptFragmentStartTime,
            action.payload.dynamic_question
          );
      })
      .addCase(postTranscriptFragment.rejected, (state, action) => {
        const { id, nextTranscriptFragmentStartTime } = action.meta.arg;

        // Set error for this transcriptFragment
        transcriptFragmentsAdapter.updateOne(state, {
          id: id,
          changes: {
            save_status: SaveStatusEnum.ERROR,
            error: action.error.message || null,
          },
        });

        // Even if we fail to save a transcript, we need to set the next question (and retry later)
        if (nextTranscriptFragmentStartTime)
          addNextTranscriptFragmentToState(
            state,
            nextTranscriptFragmentStartTime
          );
      })
      .addCase(initiateInterviewForProjectLink.fulfilled, (state, action) => {
        // If email validation is required, return early.
        if ("isEmailValidationRequired" in action.payload) {
          return;
        }

        // Otherwise, the user must have a cookie that allowed us to retrieve the full payload
        const { interview, transcript_fragments } = action.payload;

        state.interview = interview;

        mapAndSetTranscriptFragments(
          state,
          transcript_fragments,
          transcriptFragmentsAdapter
        );
      });
  },
});

const addNextTranscriptFragmentToState = (
  state: TranscriptFragmentsState,
  nextTranscriptFragmentStartTime: number,
  dynamicQuestion?: string
) => {
  // Add the next transcriptFragment with the dynamicQuestion (if specified) or the next preset question
  // End the interview if we don't have a next question
  if (!state.interview)
    throw new Error("No interview found while updating next question");

  const { questions } = state.interview;

  const nextQuestion =
    dynamicQuestion ||
    questions.find(
      (q) => !isComplexInterviewQuestion(q) && q.has_been_answered === false
    )?.question;

  if (!nextQuestion) {
    // End the interview
    state.interview = {
      ...state.interview,
      status: InterviewStatusEnum.COMPLETED,
    };

    posthog.capture(PosthogEventTypesEnum.INTERVIEW_END, {
      interview_id: state.interview.id,
    });
    // TODO: Save to server?
    return;
  }

  const nextTranscriptFragment: TranscriptFragmentPublic = {
    id: uuidv4(),
    start_time: nextTranscriptFragmentStartTime,
    end_time: null,
    question: nextQuestion,
    is_dynamic_question: Boolean(dynamicQuestion),
    save_status: SaveStatusEnum.IDLE,
    error: null,
  };

  transcriptFragmentsAdapter.addOne(state, nextTranscriptFragment);

  // If we have a dynamic question, update interview state to keep track of it
  if (dynamicQuestion) {
    const insertAtIndex = questions.findIndex(
      (q) => q.has_been_answered === false
    );

    const q: InterviewQuestion = {
      question: dynamicQuestion,
      has_been_answered: false,
      type: "dynamic",
    };

    // If all existing questions have been answered, add this question to the end
    if (insertAtIndex === -1) {
      state.interview.questions = [...questions, q];
    } else {
      state.interview.questions = [
        ...questions.slice(0, insertAtIndex),
        q,
        ...questions.slice(insertAtIndex),
      ];
    }
  }
};

export const {
  transcriptFragmentAdded,
  transcriptFragmentUpdated,
  addNextTranscriptFragment,
} = transcriptFragmentsSlice.actions;
export default transcriptFragmentsSlice.reducer;

/*
 * Selectors
 */

export const selectAllTranscriptFragments =
  transcriptFragmentsAdapter.getSelectors().selectAll;

export const selectTranscriptFragmentsState = (state: RootState) =>
  state.transcriptFragments;
export const selectInterview = (state: RootState) =>
  state.transcriptFragments.interview;

const selectLatestFromTranscriptFragmentsState = (
  state: TranscriptFragmentsState
) => {
  const { entities, ids } = state;
  if (!ids.length) return undefined;

  // See sorting in "sortComparer" above
  const latestId = ids[ids.length - 1];
  return entities[latestId];
};

export const selectLatestTranscriptFragment = (state: RootState) => {
  return selectLatestFromTranscriptFragmentsState(state.transcriptFragments);
};

// Returns whether the latest transcriptFragment is a preset question, first clarifying question, or final clarifying question
export const selectLatestTranscriptFragmentType = (state: RootState) => {
  const { entities, ids } = state.transcriptFragments;
  if (ids.length === 0) return undefined;

  const latestTranscriptFragment = entities[ids[ids.length - 1]];

  if (!latestTranscriptFragment) return undefined;

  if (!latestTranscriptFragment.is_dynamic_question) return "preset";

  // Assumes that we only ask a maximum of 2 clarifying questions in a row
  const previousTranscriptFragment = entities[ids[ids.length - 2]];
  if (previousTranscriptFragment?.is_dynamic_question) return "final_dynamic";
  else return "dynamic";
};

// Returns the current number of preset question being asked
export const selectCurrentQuestionNumber = (state: RootState) => {
  const numComplexAnswers = selectAllComplexAnswers(state).length;

  // Count the number of preset questions we've asked in state
  const presetQuestionsAsked = selectAllTranscriptFragments(
    state.transcriptFragments
  ).filter((tf) => {
    return tf.is_dynamic_question === false;
  });

  return numComplexAnswers + presetQuestionsAsked.length;
};

export const selectUnsavedTranscriptFragments: Selector<
  RootState,
  TranscriptFragmentPublic[]
> = createSelector(
  (state: RootState) => state.transcriptFragments,
  (transcriptFragments) => {
    const unsavedFragments = selectAllTranscriptFragments(
      transcriptFragments
    ).filter((tf) => {
      return tf.save_status === SaveStatusEnum.ERROR;
    });
    return unsavedFragments;
  }
);

export const selectIsSavingTranscriptFragments: Selector<RootState, boolean> =
  createSelector(
    (state: RootState) => state.transcriptFragments,
    (transcriptFragments) => {
      const fragmentBeingSaved = selectAllTranscriptFragments(
        transcriptFragments
      ).find((tf) => {
        return tf.save_status === SaveStatusEnum.SAVING;
      });
      return Boolean(fragmentBeingSaved);
    }
  );

export const selectInterviewToken = (state: RootState) =>
  state.transcriptFragments.token;

/*
 * Helpers
 */

const mapTranscriptFragmentPublicJSONToTranscriptFragmentPublic = (
  t: TranscriptFragmentPublicJSON
) => {
  return {
    id: t.id,
    start_time: new Date(t.start_time).getTime(),
    end_time: new Date(t.end_time).getTime(),
    question: t.question,
    is_dynamic_question: t.is_dynamic_question,
    save_status: SaveStatusEnum.SAVED,
    error: null,
  } as TranscriptFragmentPublic;
};

function getInterviewTokenFromUrl() {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get("t");
}

function mapAndSetTranscriptFragments(
  state: TranscriptFragmentsState,
  transcriptFragments: TranscriptFragmentPublicJSON[],
  transcriptFragmentsAdapter: EntityAdapter<TranscriptFragmentPublic>
) {
  // Map serialized transcriptFragments to TranscriptFragmentPublic
  const fragments = transcriptFragments.map(
    mapTranscriptFragmentPublicJSONToTranscriptFragmentPublic
  );
  transcriptFragmentsAdapter.addMany(state, fragments);
}

function markPreInterviewSurveyQuestionAsAnswered(
  state: TranscriptFragmentsState,
  complexAnswer: ComplexAnswerPublic
) {
  if (!state.interview)
    throw new Error("No interview found while marking question as answered");

  state.interview = {
    ...state.interview,
    questions: state.interview.questions.map((q, i) => {
      if (isComplexInterviewQuestion(q) && q.id === complexAnswer.question_id) {
        return {
          ...q,
          has_been_answered: true,
        };
      }

      return q;
    }),
  };
}

function markLatestQuestionAsAnswered(state: TranscriptFragmentsState) {
  // Mark the latest question as answered
  const questionIndex = state.interview?.questions.findIndex(
    (q) => q.has_been_answered === false
  );
  if (!state.interview)
    throw new Error("No interview found while marking question as answered");

  state.interview = {
    ...state.interview,
    questions:
      state.interview?.questions.map((q, i) => {
        if (i === questionIndex) {
          return {
            ...q,
            has_been_answered: true,
          };
        }
        return q;
      }) || [],
  };
}

import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { FC } from "react";
import { isAndroid, isIOS, isMobile } from "react-device-detect";
import { Button, ButtonVariantsEnum } from "ui";

interface MicrophoneErrorInstructionsProps {
  micPermission?: PermissionState;
}

export const MicrophoneErrorInstructions: FC<
  MicrophoneErrorInstructionsProps
> = ({ micPermission }) => {
  const renderCopy = () => {
    if (isMobile) {
      if (isIOS) {
        return (
          <>
            <b>Microphone permissions are blocked.</b>
            <br />
            <br />
            <b>To enable on iOS Safari:</b>
            <ul className="list-disc list-inside">
              <li>
                Open the Settings app on your iPhone, then scroll down and tap
                on Safari
              </li>
              <li>
                Scroll down to the "Settings for Websites" section and choose
                "Microphone"
              </li>
              <li>Make sure that "Ask" or "Allow" is selected</li>
            </ul>
            <br />
            <b>To enable on iOS Chrome:</b>
            <ul className="list-disc list-inside">
              <li>
                Open the Settings app on your iPhone, then scroll down and
                choose Chrome
              </li>
              <li>Allow Chrome to access the microphone</li>
            </ul>
          </>
        );
      }

      if (isAndroid) {
        return (
          <>
            <b>
              Microphone permissions are blocked. To enable in Chrome on
              Android:
            </b>
            <ul className="list-disc list-inside">
              <li>
                In the Chrome app, tap the three dots in the top right corner
              </li>
              <li>Select "Settings" and then "Site Settings"</li>
              <li>Select "Microphone" and ensure that Microphone is enabled</li>
              <li>Refresh this page to re-enable microphone permissions</li>
            </ul>
            <br />
            <b>To enable mic permissions for this page:</b>
            <ul className="list-disc list-inside">
              <li>
                Tap the site information icon to the left of the address bar
              </li>
              <li>
                Choose "Permissions" and then allow or reset the mic permission
                for this site
              </li>
            </ul>
          </>
        );
      }
    }

    if (micPermission === "denied")
      return (
        <>
          <b>Microphone permissions are blocked. To enable:</b>
          <ul className="list-disc list-inside">
            <li>
              In Chrome and Edge, click the lock icon in the address bar and
              then enable microphone
            </li>
            {/* <li>In Firefox, click the microphone blocked icon to update</li> */}
            <li>
              {'In Safari, choose "Safari" > "Settings for this website"'}
            </li>
          </ul>
        </>
      );

    return (
      <>
        <b>An error occurred while setting up microphone permissions</b>
        <br />
        Ensure that you're using a modern version of Chrome/Safari/Edge on
        desktop or Safari/Chrome on iOS/Android, and that your Windows or Mac
        settings have granted audio permissions to your browser.
        <br />
        <br />
      </>
    );
  };

  return (
    <>
      <div className="flex justify-center">
        <ExclamationCircleIcon className="h-8 w-8 mb-2 text-orange-500" />
      </div>
      <div className="text-gray-900 text-sm text-left">
        {renderCopy()}
        <div className="mt-4 font-bold">
          After checking permissions, refresh this page to try again:
        </div>
        <div className="flex text-center justify-center my-3">
          <Button
            variant={ButtonVariantsEnum.Secondary}
            label={"Refresh this page"}
            onClick={() => window.location.reload()}
          />
        </div>
        <div className="mt-2">
          Need help? Email us at research@alpharun.com.
        </div>
      </div>
    </>
  );
};

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  ComplexAnswerPublicJSON,
  InterviewAccessErrorsEnum,
  InterviewPublic,
  ProjectPublic,
  TranscriptFragmentPublicJSON,
} from "app-types";
import axios from "axios";
import { createAxiosInstance } from "../../api/axiosConfig";
import { RootState } from "../../app/store";
import {
  InterviewDataResponse,
  getInterviewData,
} from "../transcriptFragments/transcriptFragmentsSlice";

export interface ProjectLinkState {
  status: "idle" | "loading" | "succeeded" | "failed";
  error: InterviewAccessErrorsEnum | null;
  project: ProjectPublic | null;
  isEmailValidationRequired: boolean;
}

const initialState: ProjectLinkState = {
  status: "idle",
  error: null,
  project: null,
  isEmailValidationRequired: false,
};

export interface ProjectLinkValidationResponse {
  project: ProjectPublic;
  interview?: InterviewPublic;
  transcript_fragments?: TranscriptFragmentPublicJSON[];
  complex_answers?: ComplexAnswerPublicJSON[];
}

interface EmailValidationRequiredResponse {
  isEmailValidationRequired: boolean;
}

// Invoked on app initial load if we have a shortID in the URL.
export const validateProjectLink = createAsyncThunk<
  ProjectLinkValidationResponse,
  string,
  { rejectValue: InterviewAccessErrorsEnum }
>("projectLink/validate", async (shortId, thunkAPI) => {
  try {
    const axiosInstance = createAxiosInstance();
    const response = await axiosInstance.get(`/project-link/${shortId}`);

    // Two success response scenarios:
    // 1. No cookie set, but the link is valid and we just receive a data.project
    // 2. Cookie was already set, so we'll receive the full response payload
    // with `interview`, `transcript_fragments`, and `complex_answers` in addition to the project
    if (!response.data || !response.data.project) {
      return thunkAPI.rejectWithValue(InterviewAccessErrorsEnum.NOT_FOUND);
    }

    return thunkAPI.fulfillWithValue(response.data);
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const errorCode = error.response.data.errorCode;

      if (Object.values(InterviewAccessErrorsEnum).includes(errorCode)) {
        return thunkAPI.rejectWithValue(errorCode);
      }
    }
    return thunkAPI.rejectWithValue(InterviewAccessErrorsEnum.UNKNOWN);
  }
});

// Invoked when using a project link after the user has submitted their email.
export const initiateInterviewForProjectLink = createAsyncThunk<
  InterviewDataResponse | EmailValidationRequiredResponse,
  string,
  { rejectValue: InterviewAccessErrorsEnum }
>("projectLink/initiateInterview", async (email, thunkAPI) => {
  try {
    const shortId = selectProjectShortId(thunkAPI.getState() as RootState);

    if (!shortId) {
      return thunkAPI.rejectWithValue(InterviewAccessErrorsEnum.NOT_FOUND);
    }

    const axiosInstance = createAxiosInstance();
    const response = await axiosInstance.post(`/project-link/${shortId}`, {
      email,
    });

    // There are two possible success responses:
    // 1. If the interview was already started we'll receive a 204 and the user will
    // will receive an email with their interview link.
    // 2. If the interview is newly created, we'll receive a full payload with the
    // interview, transcript_fragments and project.
    if (response.status === 204) {
      return { isEmailValidationRequired: true };
    }

    if (!response.data) {
      return thunkAPI.rejectWithValue(InterviewAccessErrorsEnum.UNKNOWN);
    }

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const errorCode = error.response.data.errorCode;
      if (Object.values(InterviewAccessErrorsEnum).includes(errorCode)) {
        return thunkAPI.rejectWithValue(errorCode);
      }
    }
    return thunkAPI.rejectWithValue(InterviewAccessErrorsEnum.UNKNOWN);
  }
});

const projectLinkSlice = createSlice({
  name: "projectLink",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(validateProjectLink.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(validateProjectLink.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.error = null;
        state.project = action.payload.project;
      })
      .addCase(validateProjectLink.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload || InterviewAccessErrorsEnum.UNKNOWN;
      })
      .addCase(initiateInterviewForProjectLink.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(initiateInterviewForProjectLink.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.error = null;

        if ("isEmailValidationRequired" in action.payload) {
          state.isEmailValidationRequired =
            action.payload.isEmailValidationRequired;
        }
      })
      .addCase(initiateInterviewForProjectLink.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload || InterviewAccessErrorsEnum.UNKNOWN;
      })
      .addCase(getInterviewData.fulfilled, (state, action) => {
        state.project = action.payload.project;
      });
  },
});

export default projectLinkSlice.reducer;

/*
 * Selectors
 */

export const selectProjectLinkState = (state: RootState) => state.projectLink;

export const selectProjectShortId = (state: RootState) =>
  state.projectLink.project ? state.projectLink.project.short_id : null;

import {
  ComplexInterviewQuestion,
  InterviewPublic,
  isComplexInterviewQuestion,
} from "app-types";
import { ComplexAnswerPublic } from "../features/complexAnswers/complexAnswersSlice";

export const getUnansweredSurveyQuestion = (
  interview: InterviewPublic,
  lastAnswer?: ComplexAnswerPublic // Question we just answered, for case where interview.questions hasn't been updated yet
) => {
  return interview.questions.find((q) => {
    if (isComplexInterviewQuestion(q)) {
      return (
        q.has_been_answered === false &&
        (!lastAnswer || q.id !== lastAnswer?.question_id)
      );
    }

    return false;
  }) as ComplexInterviewQuestion | undefined; // Typecast bc we know the question must be complex if found
};
